import * as React from "react";

// Components
import Layout from "../../components/Layouts/Layouts";
import Seo from "../../components/Layouts/Seo";
import Banner from "../../components/Content/Banner/Index";
import LeadIn from "../../components/Content/LeadIn/Index";
import FeatureCaseStudy from "../../components/Content/FeatureArticle/Dynamic";
import FeatureApplication from "../../components/Content/FeatureApplication/Index";
import Col2 from "../../components/Content/TextMedia/Col-2";
import Col1 from "../../components/Content/TextMedia/Col-1";
import Video from "../../components/Content/Video/Index";
import TextIcon from "../../components/Content/TextIcon/Index";

// Assets
import bannerImage from "../../assets/images/banner/banner-bpress-xl.jpg";
import stainlessSteelGrip from "../../assets/images/products/landing/grip-ring.jpg";
import triplePointSeal from "../../assets/images/products/landing/triple-point-seal.jpg";
import diagram from "../../assets/images/products/landing/bpress-xl-diagram.png";
import installationVideo from "../../assets/images/products/landing/video-installation-xl.jpg";

// data
import data from "../static-pages/landing/bpress-xl.yaml";
import { UseBlogData } from "../../helpers/hooks/BlogQuery";

// markup
const IndexPage: React.FC = () => {
  const featured = UseBlogData();
  return (
    <Layout>
      <Seo
        title=">B< Press XL"
        description=">B< Press XL is a functional press fitting that provides a secure, permanent and leak-proof joint suitable for commercial applications."
      />
      <Banner
        type="product"
        title="Fitting System"
        summary='2&frac12;″ - 4″'
        divider={true}
        brandMediaUrl="logos/logo-bpress-xl.svg"
        brandMediaWidth="400px"
        brandMediaTitle="Placeholder"
        featureMediaUrl={bannerImage}
        featureMediaTitle="Placeholder"
        removePadding={true}
      />
      <LeadIn summary=">B< Press XL is a functional press fitting that provides a secure, permanent and leak-proof joint suitable for commercial applications." />
      <FeatureApplication
        sectionId="1"
        section="muted"
        heading="Applications"
        content=">B< Press XL is suitable for use across a wide range of applications depending on medium, temperature and pressure. To find out more, download our application guide."
        divider={true}
        buttonValue="Download Application Guide"
        buttonUrl="/downloads/B-Press-XL-Application-Guide.pdf"
        buttonTarget="_blank"
        buttonRel="noopener noreferrer"
        data={data.tabs}
      />
      <LeadIn
        section="muted"
        brandHeading="Features"
        paddingSize="small"
        divider={true}
      />
      <Col2
        section="muted"
        summary="
          <h2>Stainless Steel Grip Ring</h2>
          <p>The full circumference grip ring ensures all round equal grip on the tube and an even seal compression between the tube and fitting after pressing.</p>
        "
        backgroundMediaUrl={stainlessSteelGrip}
        backgroundMediaTitle="Stainless Steel Grip Ring "
        mediaPositionClass="right"
        removePadding={true}
      />
      <Col2
        section="muted"
        summary="
          <h2>Triple Point Seal</h2>
          <p>The triple point seal on the pressed fitting body counters any pressing distortion and gives greater seal contact area on the tube. The seal is self setting to ensure correct functioning, security and longevity. The seal is also not easily displaced by tube insertion.</p>
        "
        backgroundMediaUrl={triplePointSeal}
        backgroundMediaTitle="Stainless Steel Grip Ring "
        mediaPositionClass="left"
        removePadding={true}
      />
      <Col1
        section="muted"
        backgroundMediaUrl={diagram}
        backgroundMediaTitle="Placeholder"
        buttonValue="View Range"
        buttonUrl="/products?brand=>B<+Press+XL"
        buttonBottom={true}
        removePadding={true}
      />
      <LeadIn
        brandHeading="How To Install"
        buttonValue="Download Installation Guide"
        buttonUrl="/downloads/B-Press-XL-Installation-Process.pdf"
        buttonTarget="_blank"
        buttonRel="noopener noreferrer"
        divider={true}
      >
        <Video
          margin={true}
          videoMediaType="youtube"
          videoMediaId="xWTJ8oR9u7Q"
          backgroundMediaUrl={installationVideo}
          backgroundMediaTitle="Installation"
          backgroundMediaWidth="800px"
        />
      </LeadIn>
      <LeadIn
        section="default"
        subHeading="Why choose"
        brandMediaUrl="logos/logo-bpress-xl.svg"
        brandMediaWidth="280px"
        brandMediaTitle="Placeholder"
        buttonValue="Download Technical Guide"
        buttonUrl="/downloads/B-Press-XL-Technical-Brochure.pdf"
        buttonTarget="_blank"
        buttonRel="noopener noreferrer"
        divider={true}
        paddingSize="small"
      >
        <TextIcon data={data.facts} />
      </LeadIn>
      <LeadIn
        section="muted"
        subHeading="50 year"
        brandHeading="Warranty"
        divider={true}
        summary="A 50 year warranty covers against faults caused by defective manufacture of the fittings. It does not cover faults arising from incorrect installation."
        content="All >B< Press XL fittings must be installed by a licensed plumber."
        buttonValue="Download Warranty"
        buttonUrl="/downloads/B-Press-XL-Limited-Warranty.pdf"
        buttonTarget="_blank"
        buttonRel="noopener noreferrer"
      />
      <FeatureCaseStudy paddingSize="large" data={featured.bpressxl} />
    </Layout>
  );
};

export default IndexPage;
